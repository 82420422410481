import logo from "../Assets/footer-logo.png";
const Loader = () => {
  return (
    <div className="w-full h-[100vh] opacity-100  fixed top-0 z-[99999] rel bg-[#333] flex flex-col items-center justify-center">
      <img src={logo} alt="" />
      <div className="lds-ellipsis">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

export default Loader;
