import React from 'react';
import { HashLink } from 'react-router-hash-link';

const NotFoundPage = () => {
	return (
		<div>
			<div className='bg-gray-100 w-full px-3 md:px-0 h-screen flex items-center justify-center'>
				<div className='bg-white border border-gray-200 flex flex-col items-center justify-center px-4 md:px-8 lg:px-24 py-8 rounded-lg  shadow-2xl'>
					<p className='text-6xl md:text-7xl lg:text-9xl font-bold tracking-wider text-gray-300'>
						404
					</p>
					<p className='text-2xl md:text-3xl lg:text-5xl font-bold tracking-wider text-gray-500 mt-4'>
						Page Not Found
					</p>
					<p className='text-gray-500 mt-4 pb-4 border-b-2 text-center'>
						Sorry, the page you are looking for could not be found.
					</p>

					<HashLink
						to={'/'}
						className='w-full mt-4'>
						<div className='p-2 w- flex bg-lime-500  text-black items-center justify-center gap-2 cursor-pointer   rounded  text-center  font-semibold border-lime-500 border   overflow-hidden group   hover:bg-gradient-to-r hover:from-lime-500 hover:to-lime-400  hover:ring-2 hover:ring-offset-2 hover:ring-lime-400 transition-all ease-out duration-300 '>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								className='h-5 w-5'
								viewBox='0 0 20 20'
								fill='currentColor'>
								<path
									fill-rule='evenodd'
									d='M9.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L7.414 9H15a1 1 0 110 2H7.414l2.293 2.293a1 1 0 010 1.414z'
									clip-rule='evenodd'></path>
							</svg>
							<span>Return Home</span>
						</div>
					</HashLink>
				</div>
			</div>
		</div>
	);
};

export default NotFoundPage;
