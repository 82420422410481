import image from "Assets/HomePageAssets/cod.png";
import { externalLinks } from "../../../constants/external-links";

const CheckIcon = () => (
  <svg
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.0117188 7.28765C0.0117188 3.29569 3.24784 0.0595703 7.23979 0.0595703H18.0819C22.0738 0.0595703 25.31 3.29569 25.31 7.28764V18.1297C25.31 22.1217 22.0738 25.3578 18.0819 25.3578H7.2398C3.24784 25.3578 0.0117188 22.1217 0.0117188 18.1297V7.28765Z"
      fill="url(#paint0_linear_1294_733)"
    />
    <path
      d="M18.0117 8.81641L14.0761 14.534L12.6547 16.6005H10.5559C10.3513 16.6 10.1502 16.5497 9.97195 16.4544C9.79365 16.3592 9.64403 16.2221 9.53738 16.0563L8.78414 14.8939L7.30859 12.6064L8.78414 12.5865L9.81887 12.5748L11.2882 14.9467L11.3888 14.7925L14.9462 9.34367C15.0515 9.18202 15.1988 9.04869 15.3738 8.9564C15.5489 8.8641 15.7459 8.81591 15.9461 8.81641H18.0117Z"
      fill="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1294_733"
        x1="0.0117194"
        y1="25.3578"
        x2="27.0256"
        y2="2.02709"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#70C242" />
        <stop offset="1" stop-color="#B0D238" />
      </linearGradient>
    </defs>
  </svg>
);

const list = [
  {
    title: "5-Day Launch",
    description: "From zero to launch in just five days.",
  },
  {
    title: "Lead Flow & Pipeline Managment",
    description: "Keep your sales engine running smoothly.",
  },
  {
    title: "Top Selling Turnkey Solution",
    description: "We've got the blueprint for your success.",
  },
  {
    title: "Expert Sales Team",
    description: "Our experts, your extended team.",
  },
  {
    title: "Instant Brand & Target Market",
    description: "Tailored solutions, because one size does not fit all.",
  },
  {
    title: "Customize For Your",
    description: "Tailored solutions, because one size does not fit all.",
  },
];

const CampaignOnDemandSectionV2 = () => {
  return (
    <div className="w-full py-10 bg-[#F5F5F5]  px-[5%] ">
      <section className="w-full pr-8 max-w-[1560px] mx-auto">
        <h2 className="text-2xl sm:text-3xl md:text-5xl lg:text-6xl xl:text-[4rem] font-bold text-center">
          Attract{" "}
          <span className="h-[calc(1.5rem/1.5)] sm:h-[calc(1.875rem/1.5)] md:h-[calc(3rem/1.5)] lg:h-[calc(3.75rem/1.5)] xl:h-[calc(4.5rem/1.5)] w-0.5 bg-black inline-block my-auto mx-1" />{" "}
          Engage{" "}
          <span className="h-[calc(1.5rem/1.5)] sm:h-[calc(1.875rem/1.5)] md:h-[calc(3rem/1.5)] lg:h-[calc(3.75rem/1.5)] xl:h-[calc(4.5rem/1.5)] w-0.5 bg-black inline-block my-auto mx-1" />{" "}
          Convert{" "}
          <span className="h-[calc(1.5rem/1.5)] sm:h-[calc(1.875rem/1.5)] md:h-[calc(3rem/1.5)] lg:h-[calc(3.75rem/1.5)] xl:h-[calc(4.5rem/1.5)] w-0.5 bg-black inline-block my-auto mx-1" />{" "}
          Scale
        </h2>
        <p className="lg:text-2xl mt-10 text-center px-[10%]">
          Simplify your sales process with Salesbox, where growth is not just a
          goal, it's a guarantee. We build sales campaigns that are easy to
          launch & fully scalable, tailored perfectly for your B2B needs.
        </p>
        <div className="w-full flex flex-col lg:flex-row gap-10 mt-20">
          <div className="md:w-[50%]">
            <img src={image} alt="" width="100%" height="100%" className="object-contain max-h-[400px]" />
          </div>
          <div className="w-full md:w-[calc(60%-2.5rem)] h-full flex flex-col justify-center px-5 md:px-0">
            <div className="w-full md:w-max ">
              <h2 className="text-4xl md:text-5xl text-black font-bold text-center w-full  lg:text-left">
                Campaigns OnDemand<span className="font-normal">&#8482;</span>
              </h2>
              <p className="text-2xl md:text-right md:mr-10 text-center">
                Your Success, Fast-Tracked
              </p>
            </div>
            <div className="w-full grid md:grid-cols-2 gap-4 mt-10">
              {list.map((item, index) => (
                <div key={index} className="flex gap-4">
                  <div className="w-7 h-7">
                    <CheckIcon />
                  </div>
                  <div>
                    <h4 className="text-lg font-medium">{item.title}</h4>
                    <p>{item.description}</p>
                  </div>
                </div>
              ))}
            </div>
            <a href={externalLinks.calendly.salesbox} className="text-center md:text-normal mt-4 md:mt-10 hover:text-black">
              LEARN MORE {">"}
            </a>
          </div>
        </div>
      </section>
    </div>
  );
};

export default CampaignOnDemandSectionV2;
