import image1 from "Assets/HomePageAssets/grow.jpg";
import image2 from "../Assets/HomePageAssets/Group 289770.png";
import { GrowWithUsSectionV2 } from "../Components/sections";
import {
  HeroSectionV2,
  CampaignOnDemandSectionV2,
} from "../Components/sections/home";
import { externalLinks } from "../constants/external-links";

const HomeV2 = () => {
  return (
    <main className="mt-[5rem] w-screen">
      <HeroSectionV2 />
      <CampaignOnDemandSectionV2 />
      <section className="w-full max-w-[1560px] mx-auto grid lg:grid-cols-2 gap-10 my-10 px-[5%]">
        <div className="w-full h-full flex flex-col justify-center self-center justify-self-center">
          <div className="md:w-max">
            <h3 className="text-5xl font-bold text-black text-center lg:text-left">
              Grow With Salesbox
            </h3>
            <p className="text-2xl md:text-right text-center ">
              The Proven Path
            </p>
          </div>
          <p className="text-lg mt-10 text-center lg:text-left">
            Join forces with Salesbox for proven industry solutions. Our
            expertly run sales campaigns are designed for customer acquisition &
            rapid growth. It's time to take your business to the next level.
          </p>
          <a href={externalLinks.calendly.salesbox} className="mt-4 md:mt-10 text-center lg:text-left hover:text-black">
            LOOK INSIDE SALESBOX {">"}
          </a>
        </div>
        <div>
          <img src={image1} alt="" width="100%" height="100%" />
        </div>
      </section>
      <section className="w-full py-10 bg-[#F5F5F5] ">
        <div className="w-full max-w-[1560px] mx-auto grid lg:grid-cols-2 gap-10 px-[5%]">
          <div className="w-full h-full flex flex-col justify-center self-center justify-self-center">
            <div className="md:w-max">
              <h3 className="text-5xl font-bold text-black text-center lg:text-left">
                Connect with an Expert
              </h3>
              <p className="text-2xl text-center lg:text-left ">
                Ready for a sales revolution?
              </p>
            </div>
            <p className="text-lg mt-10 text-center lg:text-left">
              Scale your business with Salesbox’s team of specialists by your
              side. Offering no-cost consultations, we bring fresh insights &
              high-impact strategies straight to you.
            </p>
            <a href={externalLinks.calendly.salesbox} className="mt-4 md:mt-10 text-center lg:text-left hover:text-black">
              GET IN TOUCH TO LEARN MORE {">"}
            </a>
          </div>
          <div>
            <img src={image2} alt="" width="100%" height="100%" />
          </div>
        </div>
      </section>
      <GrowWithUsSectionV2 />
    </main>
  );
};

export default HomeV2;

// background: linear-gradient(270deg, #70C242 0%, #B0D238 100%);
