import image from "../../Assets/PriveVc.png";
import meet from "../../Assets/icons/meet.svg";
import teams from "../../Assets/icons/teams.svg";
import slack from "../../Assets/icons/slack.svg";
import zoom from "../../Assets/icons/zoom.svg";
import { sendEmail } from "../../services/email";
import { useRef } from "react";
import { externalLinks } from "../../constants/external-links";

const GrowWithUsSectionV2 = () => {
  const formRef = useRef(null);

  return (
    <section className="w-full max-w-[1560px] mx-auto my-20 relative z-0 px-[5%]">
      <div className="w-full grid lg:grid-cols-2 mt-10 gap-10">
        <div className="hidden lg:block w-full">
          <img src={image} alt="" width="100%" height="100%" />
          <a href={externalLinks.calendly.salesbox}>
            <button
              className=" text-black font-medium w-full mt-2 text-base border border-black py-2 px-4 hover:bg-primary rounded-lg transition-all duration-300 ease-out hover:border-white hover:border-2 btn-shadow"
              type="submit"
            >
              Book a Meeting
            </button>
          </a>
        </div>
        <form
          className="w-full flex flex-col justify-center items-center lg:items-start"
          ref={formRef}
          onSubmit={(e) => {
            // const formData = new FormData(formRef.current);
            sendEmail(
              e,
              formRef.current,
              process.env.REACT_APP_EMAIL_CONTACT_US_TEMPLATE_ID
            );
          }}
        >
          <div className="text-center lg:text-left">
            <h2 className="text-5xl font-bold">Let’s Grow & Connect</h2>
            <p className="text-2xl mt-5">
              Let Salesbox be the catalyst for your growth.
            </p>
            <h3 className="text-2xl font-bold mt-2">
              Elevate your sales game today.
            </h3>
          </div>
          <div className="mt-20">
            <h4 className="text-xl font-medium text-black">
              Customer Service:
            </h4>
            <div className="flex items-center gap-2">
              <svg
                width="20"
                height="20"
                viewBox="0 0 23 23"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_1294_3287)">
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M22.5865 19.9274C21.8765 22.0848 19.0951 23.1607 17.1079 22.9813C14.3939 22.736 11.4407 21.2965 9.19892 19.711C5.90379 17.3803 2.81719 13.7752 1.01859 9.94949C-0.252547 7.24622 -0.537743 3.92257 1.35286 1.45851C2.05206 0.547706 2.80799 0.0612679 3.94419 0.00453457C5.52045 -0.0721321 5.74126 0.829624 6.28252 2.23416C6.68579 3.28449 7.22399 4.35591 7.52452 5.44457C8.08725 7.47624 6.11999 7.56115 5.87159 9.22175C5.71672 10.269 6.98632 11.6735 7.55979 12.4202C8.68372 13.883 10.0346 15.1447 11.5587 16.1045C12.4312 16.655 13.8403 17.647 14.8401 17.0996C16.3795 16.2562 16.2339 13.6604 18.3867 14.539C19.5014 14.9929 20.5809 15.6479 21.645 16.2213C23.2903 17.1061 23.2136 18.023 22.5865 19.9274C23.0557 18.5045 22.1173 21.3503 22.5865 19.9274Z"
                    fill="black"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1294_3287">
                    <rect width="23" height="23" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              <a href="tel:1-888-920-GROW" className="text-xl font-medium text-primary transition-all duration-300 hover:text-black cursor-pointer">
                1-888-920-GROW
              </a>
            </div>
          </div>
          <div className="flex items-center gap-4 my-8">
            <img src={meet} alt="" />
            <img src={teams} alt="" />
            <img src={slack} alt="" />
            <img src={zoom} alt="" />
          </div>
        </form>
      </div>
    </section>
  );
};

export default GrowWithUsSectionV2;
